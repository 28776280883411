import React from "react"
import useWindowSize from "../../hooks/use-window-size"
import ArrowRight from "../ui/arrow-right"
import {
  Section,
  Button,
  Text,
  Title,
  Description,
  ButtonWrapper
} from "./index.style"

const AvailabilityOffering = () => (
  <Section>
    <Text>
      <Title>Now Offering 1 Month Free and 1 Month OP on Select Homes</Title>
      <Description>
        Advertised rents are net effective.
        <br />
        Please contact the leasing gallery for details.
      </Description>
    </Text>
    <ButtonWrapper>
      <Button>
        <a
          target="_blank"
          href={"https://www.on-site.com/apply/property/507035"}
          onClick={() => window.dataLayer ? window.dataLayer.push({ 'event': 'apply_now' }) : null}
        >
          <span>Apply Now</span> <ArrowRight fill="#EFE8DC" />
        </a>
      </Button>
    </ButtonWrapper>
  </Section>
)

export default AvailabilityOffering
